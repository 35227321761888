function baseJS(){

	//mobile toggle slide menu
	//$('.gnb').clone().appendTo('.gnbClone');
	//$('.topAccount').clone().appendTo('.accountClone');

	$('.toggleBtn').click(function(){
		toggleSlideMobi();
	});

	$('.mobile-menu').click(function(){
		toggleSlideMobi();
	});

	$('.mobiSearchBtn').click(function(){
		$('.mobiSearch').css({'top':'0'});
		$('.mobiSearch').css({'box-shadow':'5px 0px 10px rgba(0, 0, 0, 0.2)'});
	});

	$('.closeTgg').click(function(){
		$('.mobiSearch').css({'top':'-100%'});
		$('.mobiSearch').css({'box-shadow':''});
	});

	$('.mobiTggBtn:not(:first-child)').click(function(){
		$('.toggleBtn').removeClass('nav-opened');
		$('.toggleBtn').addClass('nav-closed');
		$('.maincontwrap').css({'overflow':''});
		$('.maincontwrap').css({'position':''});
		$('.header').css({'position':''});
		$('.mobiTggMenu').css({'left':'-100%'});
	});

	$('.mobi-sub-item').click(function(){
		if($('.toggleBtn').hasClass("nav-closed")) {
			$(".mobi-sub-item").slideUp(500);
        	$(".btn-open-sub").removeClass("opened");
		}else{
			toggleSlideMobi();
		}
	});

	$('.mobiTggClo').click(function(){
		$('.mobiTggMenu').css({'left':'-100%'});
	});

	//lang change
	// $(".topLang a").click(function(){
	// 	debugger
	// 	$('.topLangList').slideToggle();
	// });

	/* go top */
	$(".goTop").click(function(){
		$('html, body').animate({scrollTop: 0}, 400);
	});

	//stiky header
	$(window).scroll(function() {
		var scroll = $(window).scrollTop();
		if (scroll > 0) {
			$(".header").addClass("fix");
            $(".main-menu").addClass("sticky-main-menu");
            $(".hdTopBanner").addClass("sticky-hdTopBanner");
		} else {
			$(".header").removeClass("fix");
            $(".main-menu").removeClass("sticky-main-menu");
            $(".hdTopBanner").removeClass("sticky-hdTopBanner");
		}
	});



};

function toggleSlideMobi() {
	if($('.toggleBtn').hasClass("nav-closed")) {
		$('.toggleBtn').removeClass('nav-closed');
		$('.toggleBtn').addClass('nav-opened');
		$('.maincontwrap').css({'overflow':'hidden'});
		//$('.maincontwrap').css({'position':'fixed'});
        $('.maincontwrap').css({'position':'relative'});
		$('.header').css({'position':'fixed'});
		$('.mobiTggMenu').css({'left':'0'});
	} else {
		$('.toggleBtn').removeClass('nav-opened');
		$('.toggleBtn').addClass('nav-closed');
		$('.maincontwrap').css({'overflow':''});
		$('.maincontwrap').css({'position':''});
		$('.header').css({'position':''});
		$('.mobiTggMenu').css({'left':'-100%'});
	}
}

